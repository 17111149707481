import slider2 from "./../asset/img/slider/slider2.jpg";
import slider3 from "./../asset/img/slider/slider3.jpg";
import slider4 from "./../asset/img/slider/slider4.jpg";
import slider5 from "./../asset/img/slider/slider5.jpg";
import slider6 from "./../asset/img/slider/slider6.jpg";
import slider7 from "./../asset/img/slider/slider7.jpg";
import slider8 from "./../asset/img/slider/slider8.jpg";
import slider9 from "./../asset/img/slider/slider9.jpg";

export const DataSlider = [
  {
    img: slider2,
    alt: "slider2",
  },
  {
    img: slider3,
    alt: "slider3",
  },
  {
    img: slider4,
    alt: "slider4",
  },
  {
    img: slider5,
    alt: "slider5",
  },
  {
    img: slider6,
    alt: "slider6",
  },
  {
    img: slider7,
    alt: "slider7",
  },
  {
    img: slider8,
    alt: "slider8",
  },
  {
    img: slider9,
    alt: "slider9",
  },
];
