import {
  ApartmentOutlined,
  HomeOutlined,
  MailOutlined,
  PictureOutlined,
} from "@ant-design/icons";
import { Divider, Image, Menu, Steps } from "antd";
import { useMemo, useState } from "react";
import mucTieu from "./../../asset/img/gioi-thieu-cong-ty/muc-tieu.jpg";
import suMenh from "./../../asset/img/gioi-thieu-cong-ty/su-menh.jpg";
import tamNhin from "./../../asset/img/gioi-thieu-cong-ty/tam-nhin.jpg";
import thuNgo from "./../../asset/img/gioi-thieu-cong-ty/thu-ngo.jpg";
import "./../../scss/GioiThieu.scss";

const itemsMenu = [
  {
    key: "1",
    icon: <MailOutlined />,
    label: "THƯ NGỎ",
  },
  {
    key: "2",
    icon: <HomeOutlined />,
    label: "VỀ CÔNG TY",
  },
  {
    key: "3",
    icon: <ApartmentOutlined />,
    label: "CƠ CẤU TỔ CHỨC",
  },
  {
    key: "4",
    icon: <PictureOutlined />,
    label: "HÌNH ẢNH CÔNG TY",
  },
];

const AboutCompanyItems = [
  {
    title: "11/07/2018",
    description: (
      <div className="step-one">
        <ul>
          <li>Trụ sở chính: 125 Lê Hoàng Phái, P.17, Q.Gò Vấp, TP.HCM.</li>
          <li>Tiền thân là thương mại dịch vụ in ấn. </li>
          <li>Ngành nghề chính : In nhanh, In offset, sản xuất bao bì giấy.</li>
        </ul>
      </div>
    ),
  },
  {
    title: "2020",
    description: (
      <div className="step-one">
        <ul>
          <li>
            Trụ sở chính dời về: 164/1 Tân Thới Nhất 13, P.Tân Thới Nhất, Q.12,
            TP.HCM.
          </li>
          <li>
            Đầu tư đày đủ thiết bị, máy móc phục vụ in ấn và sản xuất thành phẩm
            khép kín chuyên phục vụ in nhanh.
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: "2024",
    description: (
      <div className="step-one">
        <ul>
          <li>
            Mở rộng quy mô sản xuất thêm phân xưởng thành phẩm bao bì offset:
          </li>
          <li>Đ/c: 368/34/46 Tân Sơn Nhì</li>
          <li>
            Với quy mô lớn và các thiết bị máy móc khép kín hiện đại nhằm đáp
            ứng nhu cầu khách hàng lớn trong các lĩnh vực : Dược Phẩm, Thú Y,
            bao bì thực phẩm, bao bì công nghiệp...
          </li>
        </ul>
      </div>
    ),
  },
];

export const GioiThieu = () => {
  const [selectedKey, setSelectedKey] = useState("2");

  const getTitlePage = useMemo(() => {
    return itemsMenu?.filter((item) => item?.key === selectedKey)?.[0]?.label;
  }, [selectedKey]);

  const renderWithKey = () => {
    switch (selectedKey) {
      case "1": {
        return (
          <div className="thu-ngo-container">
            <Image src={thuNgo} preview={false} className="image-thu-ngo" />
          </div>
        );
      }

      case "2": {
        return (
          <div className="step-one-render">
            <div className="title-company">
              CÔNG TY TNHH ĐẦU TƯ SẢN XUẤT VÀ THƯƠNG MẠI DƯƠNG GIA PHÁT.
            </div>
            <div className="sub-title">Quá Trình Hình Thành Và Phát Triển</div>
            <Steps
              progressDot
              current={2}
              direction="vertical"
              items={AboutCompanyItems}
            />

            <Divider className="divider" />
            <div className="sub-title-core">TẦM NHÌN</div>
            <img src={tamNhin} alt="tam-nhin" />
            <div className="sub-title-core">SỨ MỆNH</div>
            <img src={suMenh} alt="su-menh" />
            <div className="sub-title-core">MỤC TIÊU</div>
            <img src={mucTieu} alt="muc-tieu" />
          </div>
        );
      }

      case "3": {
        return <>Cơ Cấu Tổ Chức</>;
      }
      case "4": {
        return <>Hình Ảnh Công Ty</>;
      }

      default:
        <></>;
    }
  };
  return (
    <div className="gioi-thieu-container">
      <div className="title-gioi-thieu-page">
        {`Giới Thiệu / ${getTitlePage}`}
      </div>
      <div className="container">
        <div className="render-data">{renderWithKey()}</div>
        <div className="list-gioi-thieu">
          <Menu
            defaultSelectedKeys={["2"]}
            mode="inline"
            items={itemsMenu}
            onSelect={(e) => setSelectedKey(e?.key)}
          />
        </div>
      </div>
    </div>
  );
};
